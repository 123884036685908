import React from "react"
import Layout from "../components/layout"
import Section from "../components/shared/Section"
import Image from "../components/shared/Image"
import StyledLink from "../components/shared/StyledLink"

const Success = () => {
  return (
    <Layout>
      <Section style={{ textAlign: `center`, paddingBottom: `0` }}>
        <Image source={`/icons/success.svg`} style={{ width: `6rem` }} />
      </Section>
      <Section
        title="Thanks for reaching out!"
        subtitle="An expert will get back to you soon"
      >
        <StyledLink isPrimary to="/">
          Back to Home
        </StyledLink>
      </Section>
    </Layout>
  )
}

export default Success
